import { useState, useEffect, useCallback } from "react";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import PatientApi from "api/patient";
import AuthApi from "api/auth";
import tableData from "layouts/pages/users/reports/data/patientTableData";

function Reports() {
  const { columns } = tableData;
  const [usersList, setUserList] = useState([]);
  const [curFacility, setFacility] = useState(null);
  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState("client");
  const [averagePercentage, setAveragePercentage] = useState(0); // Add state to hold average
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    bootstrap(curFacility);
  }, [curFacility]);

  const bootstrap = useCallback(async (facility) => {
    setLoading(true); // Start loading when bootstrap starts
    const userFinded = await AuthApi.Me();
    setUser(userFinded.data);
    setUserRole(userFinded.data.role);
    if (userFinded.data.role === "client") {
      setFacility(userFinded.data.facility.Facility);
    }

    // Reset data when facility changes
    setUserList([]);
    setAveragePercentage(0);

    if (facility) {
      const patiendata = await PatientApi.getpatientsList(facility);
      if (patiendata.data && patiendata.data.length) {
        // Process user data and calculate percentage
        const userSeted = patiendata.data.map((user) => ({
          patient_full_name: user.patient_full_name || "",
          prefix: user.prefix || "",
          facility: user.facility || "",
          insurance: user.insurance || "",
          patient_percentage: `${(parseFloat(user.patient_percentage) * 100).toFixed(2)}%` || "",
        }));
        setUserList(userSeted);

        // Calculate the average patient_percentage as a percentage
        const totalPercentage = patiendata.data.reduce(
          (sum, user) => sum + (parseFloat(user.patient_percentage) || 0),
          0
        );
        const average = (totalPercentage / patiendata.data.length) * 100;
        setAveragePercentage(average); // Set the calculated average
      } else {
        setUserList([]); // Clear user list if no data
        setAveragePercentage(0); // Reset average if no data
      }
    }
    setLoading(false); // End loading after everything is set
  }, []);

  const setcurrentFacility = useCallback(async (facility) => {
    setFacility(facility);
  }, []);

  if (loading) {
    // Show a loading spinner or nothing until loading is done
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <SoftBox py={3}>
          <SoftTypography variant="h6" color="info" textAlign="center">
            Loading...
          </SoftTypography>
        </SoftBox>
        <Footer />
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <DashboardNavbar />
      <SoftBox py={3} flex={1}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <SoftBox>
                <SoftTypography variant="h5" fontWeight="bold">
                  Average Patient Percentage
                </SoftTypography>
                {curFacility ? (
                  averagePercentage > 0 ? (
                    <>
                      <SoftTypography variant="h6" fontWeight="bold" color="secondary">
                        {averagePercentage.toFixed(2)}%
                      </SoftTypography>
                      <SoftTypography variant="caption" color="textSecondary">
                        for the top payers shown below
                      </SoftTypography>
                    </>
                  ) : (
                    <SoftTypography variant="h6" fontWeight="bold" color="error">
                      No records available for the selected facility
                    </SoftTypography>
                  )
                ) : (
                  <SoftTypography variant="h6" fontWeight="bold">
                    Select Facility
                  </SoftTypography>
                )}
              </SoftBox>
            </Grid>
            {userRole !== "client" && (
              <Grid item xs={12} md={6}>
                <SoftBox display="flex" alignItems="center">
                  <SoftSelect
                    defaultValue={{ value: curFacility, label: curFacility }}
                    options={[
                      { value: "LHNJ", label: "LHNJ" },
                      { value: "LHNNJ", label: "LHNNJ" },
                      { value: "LHCC", label: "LHCC" },
                      { value: "LHCM", label: "LHCM" },
                      { value: "LHCO", label: "LHCO" },
                      { value: "WRC", label: "WRC" },
                      { value: "NSR", label: "NSR" },
                      { value: "TLR", label: "TLR" },
                      { value: "THI", label: "THI" },
                      { value: "SERC", label: "SERC" },
                      { value: "PCD", label: "PCD" },
                      { value: "DIFAD", label: "DIFAD" },
                      { value: "RRC", label: "RRC" },
                      { value: "EH", label: "EH" },
                    ]}
                    onChange={(e) => setcurrentFacility(e.value)}
                    size="small"
                  />
                  <SoftTypography variant="h6" color="secondary">
                    &nbsp;&nbsp;Facility
                  </SoftTypography>
                </SoftBox>
              </Grid>
            )}
          </Grid>

          {curFacility ? (
            usersList && usersList.length ? (
              <Table columns={columns} rows={usersList} />
            ) : (
              <SoftTypography variant="h6" >
               -
              </SoftTypography>
            )
          ) : (
            <SoftTypography variant="h6" >
              -
            </SoftTypography>
          )}
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Reports;
