import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

// @mui material components
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curved11 from "assets/images/curved-images/curved11.jpg";

import AuthApi from "../../../api/auth";

const clinicMapping = {
  "WRC": 25,
  "NSR": 26,
  "TLR": 27,
  "THI": 28,
  "SERC": 29,
  "PCD": 30,
  "LH": 31, // LH mapped to the first number; add additional mappings if needed
  "DIFAD": 36,
  "RRC": 37,
  "EH": 38,
  "IKON":39,
};

function NewUser() {
  const [agreement, setAgreement] = useState(true);
  const [formData, setFormData] = useState({
    'email': '',
    'password': 'T4k4ham12024!',
    'Primary_Key': '',
    'role': ''
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();

  const handleSetAgreement = () => setAgreement(!agreement);

  const handleChange = useCallback(e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }, [formData]);

  const handleSelectChange = useCallback((name, value) => {
    setFormData({
      ...formData,
      [name]: value
    });
  }, [formData]);

  const handleClinicChange = useCallback(selectedClinic => {
    const primaryKey = clinicMapping[selectedClinic] || '';
    handleSelectChange('Primary_Key', primaryKey);
  }, [handleSelectChange]);

  const handleSubmit = useCallback(e => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setSuccess(false);
    AuthApi.Register(formData).then(response => {
      setLoading(false);
      if (response.data.cognito_id) {
        setSuccess(true);
        setTimeout(() => navigate("/dashboards/work-in-progress"), 2000);
      } else {
        setError(response.data.msg);
      }
    }).catch(error => {
      setLoading(false);
      if (error.response) {
        return setError(error.response.data.msg);
      }
      return setError("There has been an error.");
    });
  }, [formData, navigate]);

  return (
    <CoverLayout
      title="New User Registration"
      description="Please fill the information below to create a new user account"
      image={curved11}
      top={12}
    >
      <SoftBox component="form" role="form">
        <SoftBox mb={2} lineHeight={1.25}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Role
            </SoftTypography>
          </SoftBox>
          <SoftBox mb={2} lineHeight={1.25}>
            <SoftSelect
              title="Role"
              placeholder="Role"
              options={[
                { value: "client", label: "Client" },
                { value: "admin", label: "Admin" },
              ]}
              onChange={e => handleSelectChange('role', e.value)}
            />
          </SoftBox>
        </SoftBox>
        <SoftBox mb={2} lineHeight={1.25}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Clinic
            </SoftTypography>
          </SoftBox>
          <SoftBox mb={2} lineHeight={1.25}>
            <SoftSelect
              title="Clinic"
              placeholder="Clinic"
              options={[
                { value: "WRC", label: "WRC" },
                { value: "NSR", label: "NSR" },
                { value: "TLR", label: "TLR" },
                { value: "THI", label: "THI" },
                { value: "SERC", label: "SERC" },
                { value: "PCD", label: "PCD" },
                { value: "LH", label: "LH" },
                { value: "DIFAD", label: "DIFAD" },
                { value: "RRC", label: "RRC" },
                { value: "EH", label: "EH" },
                { value: "IKON", label: "IKON" }
              ]}
              onChange={e => handleClinicChange(e.value)}
            />
          </SoftBox>
        </SoftBox>
        <SoftBox mb={2} lineHeight={1.25}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              First Name
            </SoftTypography>
          </SoftBox>
          <SoftInput placeholder="First Name" type="text" name="username" onChange={handleChange} />
        </SoftBox>
        <SoftBox mb={2} lineHeight={1.25}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Last Name
            </SoftTypography>
          </SoftBox>
          <SoftInput placeholder="Last Name" type="text" name="username" onChange={handleChange} />
        </SoftBox>
        <SoftBox mb={2} lineHeight={1.25}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Email
            </SoftTypography>
          </SoftBox>
          <SoftInput type="email" placeholder="Email" name="email" value={formData.email} onChange={handleChange} />
        </SoftBox>
       
        <SoftBox mt={2} mb={2} textAlign="center">
          {error && (
            <h6
              style={{
                fontSize: ".8em",
                color: "red",
                textAlign: "center",
                fontWeight: 400,
                transition: ".2s all",
              }}
            >
              {error}
            </h6>
          )}
          {success && (
            <h6
              style={{
                fontSize: ".8em",
                color: "green",
                textAlign: "center",
                fontWeight: 400,
                transition: ".2s all",
              }}
            >
              User created successfully
            </h6>
          )}
        </SoftBox>
        <SoftBox mt={4} mb={1} textAlign="center">
          <SoftButton variant="gradient" color="info" onClick={handleSubmit} fullWidth disabled={loading}>
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Create User'}
          </SoftButton>
        </SoftBox>
        <SoftBox mt={3} textAlign="center"></SoftBox>
      </SoftBox>
    </CoverLayout>
  );
}

export default NewUser;
